@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');

@media screen and (prefers-color-scheme: dark) {
  :root {
    --primary-color: #ffffff;
    --font-color: #d8d8d8;
    --bg-color: #313131;
    --bg-color--dark: #1c1c1c;
  }
}

@media screen and (prefers-color-scheme: light) {
  :root {
    --primary-color: #323337;
    --font-color: #555;
    --bg-color: #fff;
    --bg-color--dark: #ffffff;
  }
}


* {
  box-sizing: border-box;
}

body {
  background-color: var(--bg-color);
  margin: 0;
  font-family: 'Nunito', sans-serif;
  color: var(--primary-color);
  line-height: 1.5;
  font-size: 18px;
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--font-color);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

img {
  width: 100%;
  /* or max-width: 100%; */
  height: auto;
}

/* LOADING */

.loading {
  color: transparent !important;
  min-height: 0.8rem;
  pointer-events: none;
  position: relative;
}

.loading::after {
  animation: loading 0.5s infinite linear;
  background: 0 0;
  border: 0.1rem solid var(--bg-color);
  border-radius: 50%;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 0.8rem;
  left: 50%;
  margin-left: -0.4rem;
  margin-top: -0.4rem;
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 0.8rem;
  z-index: 1;
}

.loading.loading-lg {
  min-height: 80vh;
}

.loading.loading-lg::after {
  height: 1.6rem;
  margin-left: -0.8rem;
  margin-top: -0.8rem;
  width: 1.6rem;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Page h1 {
  line-height: 1;
  font-size: 70px;
  text-transform: uppercase;
  color: #db4949;
  font-weight: 700;
  text-align: right;
  padding-right: 30px;
  margin-bottom: 15px;
}

.Page h2 {
  line-height: 1;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: right;
  padding-right: 30px;
  margin-bottom: 15px;
  margin-top: 0;
}

.Page .inside-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.inside-body-Half-Block {
  width: 50%;
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  font-weight: 500;
}

/* Home */
.Home {
  position: relative;
  top: -126px;
  margin-bottom: -126px;
  z-index: 0;
}

/*  */

/* Service */

.page-content-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
}

.page-content-holder .divResponce {
  width: 31%;
  margin-bottom: 20px;
}

h6.page-title {
  font-size: 3.7vw;
}

/* Story */
.Img-holder {
  width: 100%;
  position: relative;
}

img.img-full-width {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.page-content-holder h3 {
  line-height: 1;
  text-decoration: none;
  font-size: 30px;
  color: #db4949;
  text-align: center;
  width: 100%;
}

.page-content-holder .divResponce-Story {
  width: 24%;
  margin-bottom: 20px;
}

h5.story-awards {
  font-size: 17px;
  line-height: 1;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 20px;
}

.divResponce-Story ul {
  list-style: disc;
  margin: 0;
  padding: 0;
  padding-left: 20px;
}

/* Work With Us */
.inside-body-left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.left,
.right {
  width: 50%;
}

img.Cont_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Contact us */
.ContDataHlder h3 {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 200%;
  margin-bottom: 10px;
}

.ContDataHlder div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.ContDataHlder p,
.ContDataHlder a {
  margin: 0;
  padding-top: 5px;
  text-decoration: blink;
}

.ContDataHlder {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1080px) {
  .Home {
    position: relative;
    top: auto;
    margin-bottom: auto;
    z-index: 0;
  }

  .inside-body-Half-Block {
    width: 100%;
  }

  .page-content-holder .divResponce {
    width: 48%;
  }

  .page-content-holder .divResponce-Story {
    width: 48%;
  }
}


@media only screen and (max-width: 700px) {

  .page-content-holder .divResponce {
    width: 100%;
  }

  h6.page-title,
  .Page h1 {
    font-size: 8vw;
  }

  .page-content-holder .divResponce-Story {
    width: 100%;
  }

  .left,
  .right {
    width: 100%;
  }
}

/* Expand */

a.Link {
  background-color: var(--primary-color);
  color: var(--bg-color);
  font-size: 28px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.Page.FOOT h1 {
  display: block;
  margin-bottom: 50px;
}

.Page.FOOT .inside-body-left {
  max-width: 1280px;
  margin: auto;
  margin-bottom: 50px;
}

@media only screen and (max-width: 600px) {
  a.Link {
    font-size: 18px;
  }
}