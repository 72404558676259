form.homeForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

form.homeForm input {
    height: 35px;
    margin-bottom: 5px;
    border: 1px solid var(--font-color);
    padding: 5px;
    border-radius: 2px;
    background: var(--bg-color--dark);
    font-size: 14px;
    width: 100%;
    color: var(--font-color);
}

form.homeForm textarea {
    margin-bottom: 5px;
    border: 1px solid var(--font-color);
    padding: 5px;
    border-radius: 2px;
    background: var(--bg-color--dark);
    font-size: 14px;
    width: 100%;
    color: var(--font-color);
}

img.PopImg {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 15px;
}

form.homeForm label {
    color: var(--primary-color);
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--font-color);
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder,
:-ms-textarea-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--font-color);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--font-color);
}

.footerHolder div {
    padding: 10px;
}

form.homeForm input.Submit {
    width: fit-content;
    background-color: #323337;
    border: none;
    color: white;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;
}

/* MODEL */


.modal {
    bottom: 0;
    display: none;
    justify-content: flex-end;
    align-items: flex-end;
    left: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0.4rem;
    position: fixed;
    right: 0;
    top: 0;
}


.modal.active,
.modal:target {
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
    z-index: 1;
}

.modal.active .modal-overlay,
.modal:target .modal-overlay {
    background: rgba(247, 248, 249, 0.75);
    bottom: 0;
    cursor: default;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.modal.active .modal-container,
.modal:target .modal-container {
    animation: slide-down 0.2s ease 1;
    z-index: 1;
}

.modal.modal-sm .modal-container {
    max-width: 320px;
    padding: 0 0.4rem;
}

.modal.modal-lg .modal-overlay {
    background: #bbbbbbcf;
}

.modal.modal-lg .modal-container {
    box-shadow: none;
    max-width: 960px;
}

.modal-container {
    background: var(--bg-color--dark);
    border-radius: 0.1rem;
    box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    max-height: 80vh;
    max-width: 640px;
    padding: 0 0.8rem;
    width: 100%;
}

.modal-container.modal-fullheight {
    max-height: 100vh;
}

.modal-container .modal-header {
    color: #303742;
    padding: 0.8rem;
}

.modal-container .modal-body {
    overflow-y: auto;
    padding: 0.8rem;
    position: relative;
}

.modal-container .modal-footer {
    padding: 0.8rem;
    text-align: right;
}

@media only screen and (max-width: 600px) {
    .modal {
        justify-content: center;
        align-items: center;
    }
}