.footerHolder {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 60px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
    border-top: 1px solid #c5c5c5;
}

ul.fotter-links-foot {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.fotter-links-foot li a {
    text-decoration: none;
    font-size: 17px;
}

.ExternalLogoHolder {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

img.external-logo {
    width: 150px;
    height: 60px;
    background-color: white;
}

ul.fotter-links-foot li.active a {
    font-weight: 600;
}

@media only screen and (max-width: 700px) {
    .footerHolder {
        padding-bottom: 100px;
    }

    .FormHolder {
        width: 100%;
    }

    .footerHolder img.site-logo {
        margin-top: 20px;
    }
}