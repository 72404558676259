nav.nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
    z-index: 1;
    position: relative;
}

svg#site-icon {
    width: 30px;
    height: 30px;
    fill: var(--primary-color);
}

svg#site-icon-white {
    width: 30px;
    height: 30px;
    fill: white;
}

.site-eazy-access li {
    list-style: none;
}

.site-eazy-access {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;
}

a.access-btn {
    border-width: 0;
    padding: 0;
    color: #fff;
    background-color: #323337;
    vertical-align: middle;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin: 0 10px;
    padding: 2px 8px 0px;
    border: 1px solid #323337;
    border-radius: 2px;
    line-height: 16px;
    background-image: none;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1;
}

a.access-btn.White {
    color: #323337;
    border: 1px solid #323337;
    background: white;
}

a.access-btn:hover {
    color: #ececec;
}

a.access-btn.White:hover {
    color: #000000;
    border: 1px solid #323337;
    background: white;
}


div#open-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

div#open-menu a {
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    list-style: none;
}

a.site-icon {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.site-eazy-access ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.off-canvas {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: nowrap;
    flex-flow: nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    align-items: center;
}

.off-canvas .off-canvas-toggle {
    display: block;
    position: absolute;
    transition: none;
    z-index: 1;
    line-height: 1;
    font-size: 30px;
    text-decoration: none;
}

.off-canvas .off-canvas-sidebar {
    background: var(--bg-color--dark);
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.25s;
    z-index: 200;
    -webkit-box-shadow: 2px 1px 5px -4px rgb(0 0 0 / 75%);
    -moz-box-shadow: 2px 1px 5px -4px rgba(0, 0, 0, .75);
    box-shadow: 2px 1px 5px -4px rgb(0 0 0 / 75%);
    padding-top: 40px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    display: flex;
    align-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    min-width: auto;
}



div#open-menu ul li.active a {
    color: #db4949;
}

.off-canvas .off-canvas-content {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 100%;
    padding: 0.4rem 0.4rem 0.4rem 4rem;
}

.off-canvas .off-canvas-overlay {
    background: rgba(48, 55, 66, 0.1);
    border-color: transparent;
    border-radius: 0;
    bottom: 0;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
}

.off-canvas .off-canvas-sidebar.active,
.off-canvas .off-canvas-sidebar:target {
    transform: translateX(0);
}

.off-canvas .off-canvas-sidebar.active~.off-canvas-overlay,
.off-canvas .off-canvas-sidebar:target~.off-canvas-overlay {
    display: block;
    z-index: 100;
}

svg#site-svg-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    fill: var(--primary-color);
}


ul.fotter-links {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

ul.fotter-links a {
    font-size: 14px !important;
    line-height: 1;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

ul.fotter-links li a {
    border-right: 1px solid gray;
}

ul.fotter-links li:last-child a {
    border: none;
}


@media (min-width: 960px) {
    .off-canvas.off-canvas-sidebar-show .off-canvas-toggle {
        display: none;
    }

    .off-canvas.off-canvas-sidebar-show .off-canvas-sidebar {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        position: relative;
        transform: none;
    }

    .off-canvas.off-canvas-sidebar-show .off-canvas-overlay {
        display: none !important;
    }
}

@media only screen and (max-width: 600px) {

    .off-canvas .off-canvas-toggle {
        font-size: 48px;
    }

    .off-canvas .off-canvas-sidebar {
        width: 70%;
    }

    .site-eazy-access {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        right: 0;
        background-color: #605e5e;
        padding: 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        z-index: 2;
    }

    svg#site-svg-icon {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    ul.fotter-links {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    div#open-menu a {
        font-size: 28px;
    }


}

@media only screen and (max-width: 500px) {
    ul.fotter-links li a {
        border-right: 0px solid transparent;
    }

}


@media only screen and (max-width: 300px) {
    .site-eazy-access {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 20px;
    }

    .site-eazy-access div {
        padding: 20px;
    }
}